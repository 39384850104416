<script>
import CustomFieldForm from "@/views/menu/configurations/customFields/customFieldForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    customFieldService: "customFieldService",
    toastService: "toastService",
  },
})
export default class CustomFieldNew extends mixins(CustomFieldForm) {
  formId = "new-customField-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.customFieldService.create(this.model);
      this.$router.push("/configurations/custom-fields/list");
      this.toastService.success(this.translations.success.customField_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.customFields_new;
  }

  afterCreate () {
    this.model = {
      code: null,
      fieldType: null,
      fieldOptions: null,
      displayOrder: 0,
      isRequired: false,
      isEditableByUser: true,
      isHiddenToUser: false,
      idCategory: null,
      localizations: [],
    };
    this.model.selectAdvancedFieldOptions = this.prepareSelectAdvancedFieldOptions();
    this.model.textAdvancedFieldOptions = this.prepareTextAdvancedFieldOptions();
    this.model.numberAdvancedFieldOptions = this.prepareNumberAdvancedFieldOptions();
    this.isReady = true;
  }
}
</script>
